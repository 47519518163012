import { POIS } from "../../reduxStore/actions/main.js";
import { center } from "@turf/turf";
import { CUMap } from "@catchupapplications/map-sdk";
import * as turf from "@turf/turf";

export const poiObjectTypes = {
  position: "Position",
  node: "Node",
  polygon: "Polygon",
};

export const poitypes = {
  address: "Adresse",
  facility: "Einrichtung",
  building: "Gebäude",
  parking: "Parkplatz",
  room: "Raum",
  campus: "Campus",
  poi: "POI",
  node: "Knoten",
  floor: "Stockwerk",
  phone: "Tel",
  event: "Veranstaltung",
  news: "Neuigkeit",
  qrCode: "QR-Code",
};

/**
 * Die Oberklasse für alle Navigationsobjekte
 */
export default class GlobalPOI {
  _id = null;
  /** @type {"en"|"de"|"fr"|"cn"} */
  static language = "de";
  /** @type CUMap */
  static map = null;

  // the higher the more prioritized
  priority = null;

  /** die offizielle Id ist jetzt die globalPoiId */
  get id() {
    return this.globalPoiId;
  }
  set id(id) {
    this._id = id;
  }
  selectedFeature;

  get searchstrings() {
    let languageSearchStrings = [];

    for (const key in this.searchTerms) {
      if (key.includes(GlobalPOI.language)) {
        languageSearchStrings = this.searchTerms[key];
      }
    }

    if (languageSearchStrings?.length === 0) {
      //  Englischer fallback, falls andere sprachen nicht zu finden sind
      languageSearchStrings = this.searchTerms["en_EN"] ?? [];
    }
    return languageSearchStrings ?? [];
  }

  constructor(props = {}) {
    const globalPoiId = props.globalPoiId ?? props.globalId ?? props.id;
    /** Diese ID ist abhängig vom Typen, dh. alle POIs vom Typ "Raum" haben eigene ids etc.  */
    this._id = props.id;
    this.globalPoiId = globalPoiId;
    this.attributeIds = props.attributeIds;
    this.additionalData = props.additionalData;
    this.type = props.type;
    this.uuid = props.uuid;
    this.name = props.name;
    this.description = props.description;
    this.iconName = props.iconName;
    this.titleImageUrl = props.titleImageUrl;
    this.category = props.category;
    this.nodeId = props.nodeId;
    // hier muss man vorsichtig sein, denn das level hier ist eigentlich ein index!
    this.level = props.level;
    this.buildingId = props.buildingId;
    this.entrances = props.entrances;
    this.preferredBuildingEntrances = props.preferredBuildingEntrances;
    this.indoors = props.indoors;
    this.address = props.address;
    this.phone = props.phone;
    this.website = props.website;
    this.priority = props.priority ?? props.prio ?? props.listPosition;
    this.parentGlobalPOIId = props.parentGlobalPOIId;
    if (props.additionalData?.visualPolygon) {
      this.visualPolygon = props.additionalData?.visualPolygon;
    }

    this.polygon = props.polygon;

    this._searchstrings =
      props.searchTerms && !props.searchstrings
        ? props.searchTerms
        : props.searchTerms ?? props.searchstrings
        ? // Legacy-mäßig wurden nur die deutschen searchstrings überliefert!
          { de_DE: props.searchstrings, en_EN: props.searchstrings }
        : {};
    this.searchTerms = this._searchstrings;
    this.businessHours = props.businessHours;
    this.campusId = props.campusId;
    this.isSearchable = props.isSearchable;
    this.relations = props.relations ?? [];
    this.position = props.position;

    if (Array.isArray(props.additionalData?.organisators)) {
      props.additionalData.organisators.forEach((o) => {
        this.relations.push(o);
      });
    }
    if (props.additionalData?.location) {
      this.relations.push(props.additionalData.location);
    }

    this.datetime = props.datetime;
    this.short = props.short;
    this.iconname = props.iconname;
    this.warning = props.warning;

    // umgewandelte Node und Polygon objekte aus den nodeId und polygon (falls vorhanden)
    this.nodeObject = null;
    this.polygonObject = null;
    this.polygonCenter = null;

    // seine Kinder (die dynamisch erst beim setzen von currentFeature eingetreagen werden!)
    // initial null, wenn einmalgesetzt -> Array (auch leeres Array)
    this.children = null;
    this.parent = null;

    // Das Feature, das auf der actual Map ausgwählt werden soll (Node, Polygon)
    // Damit sind UI und Map "currentFeature" distinkt voneinander.
    // So können bei den gleichen POIs andere Features auf der Karte angezeigt werden (z.B. Parent Gebäude von einrichtungen)
    this._selectedFeature = null;

    // wenn POI Cards vom UI und nicht von der Map ausgewählt werden muss
    // entschieden werden, ob Polygon oder Node bevorzugt werden soll!

    // if (props.globalPoiId === 71908) {
    //   console.log("Ich habe meine GiD gefunden tun:", props.globalPoiId, props);
    // }

    if (props.type === poitypes.building && props.polygon) {
      this.preferredSelectedFeature = poiObjectTypes.polygon;
    } else if (props.nodeId) {
      this.preferredSelectedFeature = poiObjectTypes.node;
    } else if (props.position) {
      this.preferredSelectedFeature = poiObjectTypes.position;
    } else if (props.polygon) {
      this.preferredSelectedFeature = poiObjectTypes.polygon;
    }
  }

  /** Gibt den searchstring zurück, der übereinstimmt mit dem gegebenen string */
  includesSearchString(searchText) {
    return this.searchstrings.find((ss) =>
      ss.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  /**
   * @param {[number, number]} value
   */
  setPolygonCenter = (value) => {
    this.polygonCenter = value;
  };
  // Polygone und Nodes als Referenz im POI hinterlegen!
  setNodeObject(node) {
    this.nodeObject = node;
  }
  setPolygonObject(polygon) {
    this.polygonObject = polygon;
    this.setPreferredFeatureAsSelected();
  }
  hasLocation() {
    if (this.position) {
      return poiObjectTypes.position;
    }
    if (this.nodeObject) {
      return poiObjectTypes.node;
    }
    if (this.polygonObject) {
      return poiObjectTypes.polygon;
    }
    return false;
  }
  /**
   * Wichtig für Mapbox damit es weiß, ob es ein Polygon oder ein Node auf der Map markieren muss!
   * @param {Node, Polygon} feature
   */
  setSelectedFeature(feature) {
    this.selectedFeature = feature;
  }

  /**
   * Das Preferred Feature als selected Feature setzen
   * wenn preferred nicht vorhanden ist, jeweils das andere setzen
   * @ return selectedFeature
   */
  setPreferredFeatureAsSelected() {
    // wenn ein Feature bereits als selected gilt, dann ist es wahrscheinlich bereits ausgwählt
    // if (this.selectedFeature) return;

    if (
      this.preferredSelectedFeature === poiObjectTypes.position &&
      this.position
    ) {
      this.selectedFeature = turf.point(
        [this.position.lng, this.position.lat],
        { level: this.position.z }
      );
    } else if (
      this.preferredSelectedFeature === poiObjectTypes.polygon ||
      (!this.preferredSelectedFeature && this.polygonObject)
    ) {
      if (this.polygonObject) {
        this.selectedFeature = this.polygonObject;
      } else if (this.nodeObject) {
        this.selectedFeature = this.polygonObject;
      }
    } else if (
      this.preferredSelectedFeature === poiObjectTypes.node ||
      (!this.preferredSelectedFeature && this.nodeObject)
    ) {
      if (this.nodeObject) {
        this.selectedFeature = this.nodeObject;
      } else if (this.polygonObject) {
        this.selectedFeature = this.nodeObject;
      }
    }
  }
  getPreferredFeature() {
    // PH: Müsste wohl noch rein, wird aber eh nicht benutzt, bleibt daher draussen.
    // if (
    //   this.preferredSelectedFeature === poiObjectTypes.position &&
    //   this.position
    // ) {
    //   // console.log("WENN ICH DAS HIER LESE DANN IST GUT!!!!");
    //   // return this.position;
    //   return turf.point([this.position.lng, this.position.lat]);
    // }
    if (this.selectedFeature) return this.selectedFeature;

    if (
      this.preferredSelectedFeature === poiObjectTypes.polygon &&
      this.polygonObject
    ) {
      return this.polygonObject;
    }
    if (
      this.preferredSelectedFeature === poiObjectTypes.node &&
      this.nodeObject
    ) {
      return this.nodeObject;
    }
  }

  /**
   * Gibt die richtige Node für Routes. Bei normalen POIs gibt es
   * einfach nur die Node und bei PolygonPOIs gibt es die
   * NodeId des besten Eingangs zurück
   */
  getRouteNodeId() {
    let preferredRoutingNode = this.nodeId;
    try {
      if (
        Array.isArray(this.preferredBuildingEntrances) &&
        this.preferredBuildingEntrances[0] != null
      ) {
        preferredRoutingNode = this.preferredBuildingEntrances[0].addressId;
      } else if (
        this.type === poitypes.building &&
        Array.isArray(this.entrances)
      ) {
        // besten Eingang aussuchen
        this.entrances.forEach((e) => {
          const entrance = POIS.addresses.find((p) => p.id === e.addressId);

          if (
            entrance &&
            entrance.nodeId != null &&
            entrance.additionalData?.isMainEntrance
          ) {
            preferredRoutingNode = entrance.nodeId;
          }
        });
      }
    } catch (e) {
      console.warn("konnte keine gute nodeId finden");
      preferredRoutingNode = this.nodeId;
    }

    return preferredRoutingNode;
  }
  /**
   * Returned die Parents von diesem POI
   */
  getParent() {
    if (this.parents == null) {
      if (this.parentGlobalPOIId) {
        // POIS.all.forEach((poi) => {
        //   // Das ist die Elternpoi des POIs (meistens oder immer nur 1)
        //   if (this.parentGlobalPOIId == poi.id) {
        //     this.parent = poi;
        //   }
        // });
        this.parent = POIS.all_objects[this.parentGlobalPOIId];
      }
    }
    return this.parent;
  }

  /**
   * Iteriert durch alle POIs durch und referenziert sich seine Kinder in einem Array
   * @return [POI] children
   */
  getChildren() {
    if (this.children == null) {
      this.children = [];
      // einrichtungsId nehmen
      // alle anderen Einrichtungen nach dieser Id iterieren
      POIS.all.forEach((poi) => {
        // Kinder der this. Einrichtung suchen
        if (poi.parentGlobalPOIId === this.id) {
          this.children.push(poi);
        }
      });
    }

    return this.children;
  }

  /**
   * gibt den Center des Preferred Features vom POI zurück, unabhängig ob Node oder Polygon
   * @return {[lng,lat]}
   */
  getFeatureCenter() {
    // console.log("Ich werde gecallt!");

    this.setPreferredFeatureAsSelected();

    if (this.selectedFeature) {
      if (this.selectedFeature.type === "Point") {
        // console.log("1 Ich returneiiei");
        return center(this.selectedFeature.geometry).geometry.coordinates;
      } else if (this.position != null) {
        // console.log("2 Ich returnen position", this.position);
        return [this.position.lng, this.position.lat];
      } else {
        if (this.polygonCenter && Array.isArray(this.polygonCenter)) {
          // console.log("3 Ich returniere polygonCenter");
          return this.polygonCenter;
        } else {
          // console.log("4 Ich returne wieder was anderes.");
          return center(this.selectedFeature.geometry).geometry.coordinates;
        }
      }
    } else {
      return [this.position.lng, this.position.lat];
      throw Error(
        `getFeatureCenter in poi.class.js Feature: ${JSON.stringify(
          this.selectedFeature
        )}`
      );
    }
  }
}

/**
 *
 * @param {[GlobalPOI]} childrenPOIs
 * @return {[Feature]}
 */
export function getNewChildrenFeatures(childrenPOIs, source) {
  let childrenFeatures = [];
  if (childrenPOIs) {
    childrenPOIs.forEach((childPOI) => {
      if (childPOI.getPreferredFeature && childPOI.getPreferredFeature()) {
        let feature = {
          type: "Feature",
          properties: {
            level: childPOI.level,
            poiId: childPOI.id,
          },
          geometry: { type: "Point", coordinates: childPOI.getFeatureCenter() },
        };
        feature.id = childPOI.id;
        feature.source = source;
        childrenFeatures.push(feature);
      }
    });
  }
  return childrenFeatures;
}

/**
 * Fügt den NodeId Relation Arrays echte Nodes hinzu mit ihrer Art der Relation zueinander
 * @param {object} item
 * @param relationType
 */
export function addRelationToPOI(item, relationType) {
  // Zahlen in den Relations sind Node Ids

  item.relations.forEach((id) => {
    const poi = POIS.all_objects[id];
    if (poi) {
      if (!Array.isArray(poi.relations)) {
        poi.relations = [];
      }
      poi.relations = poi.relations.filter((p) => p !== item.id);
      poi.relations.push(item.id);
    }
  });
}
