import React, { useEffect, useState, useRef } from "react";
import Fuse from "fuse.js";
import { FormattedMessage, useIntl } from "react-intl";
import { options } from "../../../configuration/fuzzy";
import { getEvent } from "../../events/EventList.component";
import Phonebook from "../Entries/Phonebook.component";
import Event from "../Entries/Event.component";
import News from "../Entries/News.component";
import POI from "../Entries/POI.components";
import QRCode from "../Entries/QRCode.component";
import { poitypes } from "../../../functions/classes/poi.class.js";
import {
  CAMPUS_COLLECTION,
  SORT_ORDER,
} from "../../../reduxStore/actions/main";
import StandardList from "../../elements/wrapper/standardList.wrapper.jsx";
import StandardButton from "../../elements/buttons/standard.button.jsx";
import { AppOverlayIFrame } from "../../../functions/classes/miscelleanous.classes.js";
import StandardInstruction from "../../elements/text/standardInstruction.component.jsx";
import { statisticLogger } from "../../statisticLogger/StatisticLogger.container";
import { Button } from "react-bootstrap";
import { terminalNavigationLock } from "../../../app/app.sideEffects/useURLParams.sideEffects";

/**
 *
 * @param pois
 * @param categories
 * @param searchText
 * @param selectedCategories
 * @param {String[]} selectedCampuses
 * @param searchItemClicked
 * @param setQRCode
 * @param setMatches
 * @param setCurrentAppOverlay
 * @return {JSX.Element}
 * @constructor
 */
export default function List({
  pois,
  categories,
  searchText,
  selectedCategories,
  selectedCampuses,
  searchItemClicked,
  setQRCode,
  setMatches,
  setCurrentAppOverlay,
  isTerminal,
  resetCampus,
  resetCategories,
}) {
  const intl = useIntl();
  const filterTimer = useRef();
  const [, setPOIsFilteredByCampus] = useState([]);
  const [poisFilteredSelectedCategory, setPOIsFilteredSelectedCategory] =
    useState([]);
  const [poisFilteredCategoryAndText, setPOIsFilteredCategoryAndText] =
    useState([]);
  const [categoriesWithPois, setCategoriesWithPois] = useState([]);
  const campusCount = CAMPUS_COLLECTION.getAllCampuses().length;

  const handleShowEvent = (id) => {
    const item = pois.find((poi) => poi.id === id);
    const event = getEvent(item);
    if (item.website) {
      setCurrentAppOverlay(
        new AppOverlayIFrame(item.name, item.website, {
          event: event,
          contentType: "event",
        })
      );
    }
  };

  const handleShowNews = (id) => {
    const item = pois.find((poi) => poi.id === id);
    if (item.website) {
      setCurrentAppOverlay(
        new AppOverlayIFrame(item.name, item.website, { contentType: "news" })
      );
    }
  };

  const getTextFilteredPOIs = () => {
    const fuse = new Fuse(
      poisFilteredSelectedCategory.filter((poi) => 1 === poi.isSearchable),
      options
    );
    const searchResult = fuse.search(searchText);

    return (
      <>
        <StandardInstruction className="text-center mb-3">
          {searchResult.length ? (
            <FormattedMessage
              id="search.onlyFuzzySearch"
              values={{ searchText: searchText }}
            />
          ) : poisFilteredSelectedCategory.length ? (
            <FormattedMessage
              id="search.fuzzySearchCategories"
              values={{ searchText: searchText }}
            />
          ) : (
            <FormattedMessage
              id="search.noFuzzySearch"
              values={{ searchText: searchText }}
            />
          )}
        </StandardInstruction>
        {searchResult.map((entry) => {
          // wenn location verlangt ist, dann alle ohne location rauswerfen!
          if (!entry.item.hasLocation) return;

          switch (entry.item.type) {
            case poitypes.phone:
              return (
                <Phonebook
                  key={entry.refIndex}
                  name={entry.item.name}
                  searchText={searchText}
                  searchstrings={entry.item.searchstrings}
                  phone={entry.item.phone}
                />
              );
            case poitypes.event:
              return (
                <Event
                  key={entry.refIndex}
                  id={entry.item.id}
                  name={entry.item.name}
                  searchText={searchText}
                  searchstrings={entry.item.searchstrings}
                  showEvent={handleShowEvent}
                />
              );
            case poitypes.news:
              return (
                <News
                  key={entry.refIndex}
                  id={entry.item.id}
                  name={entry.item.name}
                  searchText={searchText}
                  searchstrings={entry.item.searchstrings}
                  description={entry.item.description}
                  showNews={handleShowNews}
                />
              );
            case poitypes.qrCode:
              return (
                <QRCode
                  key={entry.refIndex}
                  name={entry.item.name}
                  searchText={searchText}
                  destinationId={entry.item.destinationId}
                  startNodeId={entry.item.startNodeId}
                  setQRCode={setQRCode}
                />
              );
            default:
              return (
                <POI
                  ariaLabel={intl.formatMessage(
                    {
                      id: "search.screenreader.poiCard",
                    },
                    {
                      poiName: entry?.item.name,
                      poiAddress: entry?.item.address,
                      category: entry?.item.category,
                    }
                  )}
                  key={entry.refIndex}
                  id={entry.item.id}
                  name={entry.item.name}
                  searchText={searchText}
                  searchstrings={entry.item.searchstrings}
                  address={entry.item.address}
                  searchItemClicked={searchItemClicked}
                  poi={entry.item}
                />
              );
          }
        })}
      </>
    );
  };
  // Das sind die kategorisierten POIs
  const filterBySearchText = () => {
    clearTimeout(filterTimer.current);

    filterTimer.current = setTimeout(() => {
      if (searchText) {
        statisticLogger.addLog({
          action: {
            group: "Search",
            id: "searchtextInput",
            movement: "stay",
            name: "Search Text Input",
            type: "input",
            interaction: "typing",
            content: { searchText: searchText },
          },
        });
      }
      // filter out pois that do not match the selected campuses!
      const poisFilteredBySelectedCampus = pois.filter((poi) => {
        return !(
          selectedCampuses.length &&
          !selectedCampuses.find((id) => id === poi.campusId)
        );
      });
      setPOIsFilteredByCampus(poisFilteredBySelectedCampus);

      // filter out pois that are not within the selected categories
      const poisFilteredBySelectedCategory =
        poisFilteredBySelectedCampus.filter((poi) => {
          return !(
            selectedCategories.length &&
            !selectedCategories.find((id) => id === poi.category)
          );
        });
      setPOIsFilteredSelectedCategory(poisFilteredBySelectedCategory);

      // Filter out the pois, that do not match the text input
      const poisFilteredBySearchText = searchText.length
        ? poisFilteredBySelectedCategory.filter(
            (poi) =>
              getSearchFilterText(searchText, [poi.name]) ||
              getSearchFilterText(searchText, [poi.address]) ||
              getSearchFilterText(searchText, poi.searchstrings) ||
              getSearchFilterText(searchText, [poi.description])
          )
        : poisFilteredBySelectedCategory;
      setPOIsFilteredCategoryAndText(poisFilteredBySearchText);

      // Filter out the categories with not pois at all
      const localSting =
        intl.locale === "de"
          ? "de_DE"
          : intl.locale === "en"
          ? "en_EN"
          : intl.locale;

      const categoriesWithContent = categories
        .map((category) => ({
          id: category.id,
          name: category.bezeichnungLabel[localSting] ?? category.name,
          prio: category.prio,
          pois: poisFilteredBySearchText.filter((p) => {
            return p.category === category.id;
          }),
        }))
        .filter((category) => category.pois.length)
        .sort((a, b) => b.prio - a.prio);

      setCategoriesWithPois(categoriesWithContent);
    }, 700);
  };

  useEffect(filterBySearchText, [
    pois,
    selectedCategories,
    selectedCampuses,
    searchText,
  ]);

  useEffect(() => {
    setMatches(poisFilteredCategoryAndText.length);
  }, [poisFilteredCategoryAndText]);

  const resetFilter = () => {
    resetCampus();
    resetCategories();
  };

  return (
    <div
      className={`d-flex flex-column pr-3 pl-3 pt-3${
        searchText.length ? " in-search" : ""
      }`}
      // schutz wenn man zum ende scrollt (wegen virtual keyboard und so)
      style={{ paddingBottom: "50%" }}
    >
      {!poisFilteredCategoryAndText.length
        ? getTextFilteredPOIs()
        : categoriesWithPois.map((cat) => (
            <Category
              key={cat.id}
              cat={cat}
              searchItemClicked={searchItemClicked}
              searchText={searchText}
              showEvent={handleShowEvent}
              showNews={handleShowNews}
              setQRCode={setQRCode}
            />
          ))}

      {searchText.length &&
      (selectedCategories.length !== 0 ||
        selectedCampuses.length !== campusCount) ? (
        <button
          className="w-100 mb-2 text-center btn  align-items-center rounded-5  ca-btn-hollow mb-3 mt-5 mr-2"
          onClick={resetFilter}
        >
          <FormattedMessage id="search.resetAllFilters" />
        </button>
      ) : null}
    </div>
  );
}

/**
 * Das ist eine einzige Category
 * @param cat
 * @param searchItemClicked
 * @param searchText
 * @param shownItems
 * @param showEvent
 * @param showNews
 * @param setQRCode
 * @return {JSX.Element}
 * @constructor
 */
function Category({
  cat,
  searchItemClicked,
  searchText,
  shownItems = 7,
  showEvent,
  showNews,
  setQRCode,
}) {
  const intl = useIntl();
  // Liste wird dynamisch gefüllt, wenn ungefüllt werden nur 3 Objekte dieser Kategorie gerendert
  const [numberOfPois, setNumberOfPois] = useState(shownItems);
  const [sortedPOIs, setSortedPOIs] = useState([]);
  const getPOIs = () => {
    let poiArray = [];

    for (let i = 0; i < numberOfPois && i < sortedPOIs.length; i++) {
      let poi = sortedPOIs[i];
      if (poi) {
        if (poitypes.phone === poi.type) {
          poiArray.push(
            <Phonebook
              key={poi.id + "phone"}
              name={poi.name}
              searchText={searchText}
              searchstrings={poi.searchstrings}
              phone={poi.phone}
              campusId={poi.campusId}
            />
          );
        } else if (poitypes.event === poi.type) {
          poiArray.push(
            <Event
              key={poi.name + "event"}
              id={poi.id}
              name={poi.name}
              searchText={searchText}
              searchstrings={poi.searchstrings}
              showEvent={showEvent}
            />
          );
        } else if (poitypes.news === poi.type) {
          poiArray.push(
            <News
              key={poi.id + "news"}
              id={poi.id}
              name={poi.name}
              searchText={searchText}
              searchstrings={poi.searchstrings}
              description={poi.description}
              showNews={showNews}
            />
          );
        } else if (poitypes.qrCode === poi.type) {
          poiArray.push(
            <QRCode
              key={poi.name + "qr"}
              name={poi.name}
              searchText={searchText}
              destinationId={poi.destinationId}
              startNodeId={poi.startNodeId}
              setQRCode={setQRCode}
            />
          );
        } else {
          poiArray.push(
            <POI
              ariaLabel={intl.formatMessage(
                {
                  id: "search.screenreader.poiCard",
                },
                {
                  poiName: poi.name,
                  poiAddress: poi.address,
                  category: cat.name,
                }
              )}
              key={poi.id + "poi"}
              id={poi.id}
              name={poi.name}
              searchText={searchText}
              searchstrings={poi.searchstrings}
              address={poi.address}
              searchItemClicked={
                terminalNavigationLock ? null : searchItemClicked
              }
              poi={poi}
            />
          );
        }
      }
    }

   return poiArray.sort((a, b) => a.props?.poi?.priority - b.props?.poi?.priority);
  };

  useEffect(() => {
    // pois die hier reinkomen sind alle einheitlich in ihrer Kategorie,
    // daher kann der erste poi zur unterscheidung der Sortierungsart genommen werden
    let pois = [];

    pois = cat.pois.sort((a, b) => a.priority - b.priority);
    setSortedPOIs(pois);
  }, [cat, searchText, shownItems]);

  return (
    <StandardList
      heading={cat.name}
      ariaLabel={
        intl.formatMessage({
          id: "search.filterDetails.screenreader.category",
        }) + cat.name
      }
      className={"mb-3 standardList__search"}
      headingType={"h4"}
    >
      {getPOIs()}
      {cat.pois.length > numberOfPois ? (
        <StandardButton
          buttonClasses={
            "normalButton--naked normalButton--naked--showMore normalButton--naked__searchCard"
          }
          handleClick={() => setNumberOfPois((prevState) => prevState + 2500)}
        >
          <FormattedMessage id="search.showMore" />
        </StandardButton>
      ) : null}
    </StandardList>
  );
}

export function getSearchFilterText(searchText, searchStrings) {
  if (!searchText.length) return true;
  if (!searchStrings) return "";

  let result = false;
  searchStrings.forEach((searchString) => {
    if (searchString == null) {
      return;
    }

    const normalizedSearchString = searchString
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    const words = searchText
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .split(" ");

    const neededMatches = words.length;
    let matches = 0;
    words.forEach((word) => {
      if (normalizedSearchString.includes(word)) {
        matches++;
      }
    });

    if (neededMatches === matches) {
      result = ` (${searchString})`;
      return true;
    }
  });

  return !result ? "" : result;
}
